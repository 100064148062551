import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Logo from '../components/Logo';
import Wrapper from '../components/Wrapper';

import SEO from '../components/seo';
import klinkmade from '../images/klinkmade.svg';
import * as styles from './index.module.scss';

const IndexPage = () => (
  <Wrapper>
    <SEO title="" />
    <div className={styles.splash}>
      <div className={styles.splashInner}>
        <Logo className={styles.logo} />
        <nav>
          <ul>
            <li>
              <a href="/resume">Resumé</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </Wrapper>
);

export default IndexPage;
